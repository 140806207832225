import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Logo from "../../component/Logo";
import CloseIcon from "@material-ui/icons/Close";
import MetaMaskpopup from "src/component/MetaMaskpoppup";
import { useWeb3React } from "@web3-react/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { UserContext } from "src/context/User";
import { sortAddress } from "src/utils";
const headersData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "About",
    href: "/about-us",
  },

  // {
  //   label: "Breed",
  //   href: "/breed",
  // },
  {
    label: "Events",
    href: "/event",
  },
  {
    label: "Mint",
    href: "/mint",
  },
  {
    label: "Leader Board",
    href: "/top-rankers",
  },
  {
    label: "Pet Store",
    href: "/select-dog",
  },
  // {
  //   label: 'Learn',
  //   href: '/learn',
  // },
  {
    label: "Profile",
    href: "/profile",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    fontFamily: "Poppins",
    minWidth: "auto",
    color: "#383838",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    textDecoration: "none",

    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#029CDC",
      borderBottom: "4px solid #029CDC",
    },

    "&:hover": {
      color: "#029CDC",
      // borderBottom:"4px solid #029CDC",
    },
  },

  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",

    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "20px",
    width: "100px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: " #029CDC",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& a": {
      width: "100% !important",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "6px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    width: "100%",
    //  borderBottom: "1px solid #3e3e3e",
    padding: "0px 0px 0px 17px",
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
    paddingLeft: "15px",
  },
  mainHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
}));

export default function Header() {
  const { account, chainId } = useWeb3React();
  const user = useContext(UserContext);
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    wallet,
    submenu,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [isConnected, setIsConnected] = useState([]);
  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" style={{ paddingLeft: "0", paddingRight: "0" }}>
        <Toolbar className={`scrollbar-hidden ${toolbar}`}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {getMenuButtons()}

            <Button
              variant="contained"
              size="large"
              color="secondary"
              target="_blank"
              style={{ marginLeft: "50px" }}
              // onClick={() => history.push("/dash")}
              onClick={() => {
                user.setOpenMetaMask(true);
              }}
            >
              {account ? `${`${sortAddress(account)}`}` : "CONNECT"}
            </Button>
            <Popper
              open={open1.community}
              anchorEl={anchorRef.community.current}
              role={undefined}
              transition
              disablePortal
              className={submenu}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={(e) => handleClose2(e, "community")}
                    >
                      <MenuList
                        autoFocusItem={open1}
                        id="menu-list-grow"
                        onKeyDown={(e) => handleListKeyDown(e, "community")}
                      >
                        <MenuItem component={Link} to="/fungy-token">
                          Fungy Token
                        </MenuItem>
                        <MenuItem component={Link} to="/faq">
                          Discussion
                        </MenuItem>
                        <MenuItem>Voting</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={`scrollbar-hidden ${mainHeader}`}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {/* <img className={logoDrawer} src="images/hovrlogo.png" alt="" /> */}
            {getDrawerChoices()}

            <Container>
              {" "}
              <Button
                variant="contained"
                size="large"
                color="secondary"
                target="_blank"
                onClick={() => user.setOpenMetaMask(true)}
              >
                {account ? `${`${sortAddress(account)}`}` : "CONNECT"}
              </Button>
            </Container>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#197ab3", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return isConnected.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return isConnected.map(({ label, href }) => {
      return (
        <>
          <NavLink
            exact
            activeClassName="active"
            {...{
              key: label,
              color: "inherit",
              to: href,
              // component: Link,
              className: menuButton,
            }}
          >
            {label}
          </NavLink>
        </>
      );
    });
  };
  useEffect(() => {
    if (account) {
      setIsConnected(headersData);
    } else {
      const filterFun = headersData.filter((data) => {
        return data?.label !== "Profile" && data.label !== "Pet Store";
      });
      setIsConnected(filterFun);
    }
  }, [account]);

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "absolute" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
        className="scrollbar-hidden"
      >
        <Box
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={`scrollbar-hidden ${containerHeight}`}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>
      <Dialog
        open={user?.openmetamask}
        fullWidth
        maxWidth="xs"
        onClose={() => user.setOpenMetaMask(false)}
      >
        <Box
          style={{
            background: "linear-gradient(90deg, #39A9F4 0%, #62D3F0 100%)",
          }}
          align="right"
        >
          <IconButton onClick={() => user.setOpenMetaMask(false)}>
            <CloseIcon style={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
        <DialogContent>
          <MetaMaskpopup user={user} account={account} />
        </DialogContent>
      </Dialog>
    </>
  );
}
