import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import GameLayout from "./layouts/GameLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Aboutbanner")),
  },
  {
    exact: true,
    path: "/event",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Event/Event")),
  },
  // {
  //   exact: true,
  //   path: "/learn",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Home/ LearnSetWallet/LearnSetWallet")
  //   ),
  // },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/event",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Event/Event")),
  },
  {
    exact: true,
    path: "/mint",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Mint/Index")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/Home/ExploreMarketplace")),
  },
  {
    exact: true,
    path: "/race-time",
    component: lazy(() => import("src/component/RaceTime")),
  },
  // Profile section
  {
    exact: true,
    path: "/profile",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/Home/Profilepage/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,

    component: lazy(() =>
      import("src/views/pages/Home/Profilepage/EditProfile")
    ),
  },
  {
    exact: true,
    path: "/breed",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Breed")),
  },
  {
    exact: true,
    path: "/top-rankers",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Top-Rankers")),
  },
  {
    exact: true,
    path: "/newbreed",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/NewBreed")),
  },
  {
    exact: true,
    path: "/raceresult",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/RaceResult")),
  },
  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Footersection/Privacy")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Footersection/TermsCondition")
    ),
  },
  {
    exact: true,
    path: "/aboutus",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Footersection/Aboutus")),
  },
  {
    exact: true,
    path: "/racenow",
    layout: GameLayout,
    component: lazy(() => import("src/views/pages/Home/RaceNow/Racenow")),
  },
  {
    exact: true,
    path: "/select-dog",
    layout: HomeLayout,
    component: lazy(() => import("src/component/SelectDog")),
  },
  {
    exact: true,
    path: "/listevent",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Event/ListEvent")),
  },
  {
    exact: true,
    path: "/eventmanagement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dashboard/Eventmanagement")
    ),
  },
  {
    exact: true,
    path: "/addevent",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Dashboard/AddEvent")),
  },
  {
    exact: true,
    path: "/admin-listevent",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Event/ListEvent")),
  },

  {
    exact: true,
    path: "/admin-addevent",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Dashboard/AddEvent")),
  },
  {
    guard: true,
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Dashboard/Dashboard")),
  },
  {
    guard: true,
    exact: true,
    path: "/event-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Dashboard/AddEvent")),
  },

  // Login file
  {
    exact: true,
    path: "/login",
    // guard: true,
    component: lazy(() => import("src/views/auth/LogIn/index")),
  },
  {
    exact: true,
    path: "/fotgot-password",
    // guard: true,
    component: lazy(() => import("src/views/auth/forgot-password/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    // guard: true,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  // Dashboard file

  {
    guard: true,
    exact: true,
    path: "/eventmanagement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dashboard/Eventmanagement")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/practice-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Home/Dashboard/PracticeManagement/PracticeManagement"
      )
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/Add-Practice",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dashboard/PracticeManagement/AddPractice")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dashboard/staticManagement/StaticManagement")
    ),
  },
  {
    exact: true,
    path: "/social-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dashboard/socialManagement/socialMagements")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dashboard/staticManagement/Edit")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/store-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dashboard/StoreManagement")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/usermanagemant",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Dashboard/Usermanagement")
    ),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
