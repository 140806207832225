import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiStepIcon: {
      text:{
        fill:'#fff !importnt',
        color:'#fff',
      },
      root: {
        color:'#C9C9C9',
        "& .MuiStepIcon-active": {
          color: "#47bcf2 !important",
        },
      },
    },
    MuiStepLabel: {
      label: {
        "&.MuiStepLabel-active": {
          color: " #47bcf2 ",
        },
        "& .MuiStepLabel-completed": {
          color: "#47bcf2 !important",
        },
      },
      root: {
        "& .MuiStepIcon-completed": {
          color: "#47bcf2 !important",
        },
      },
    },
    MuiAppBar:{
      positionAbsolute:{
        position:"fixed !important"
      },
      colorPrimary:{
            backgroundColor:"#fff !important",
          },
      positionRelative:{
        position:"fixed !important"
      }
    },
    // MuiAppBar:{
    //   colorPrimary:{
    //     backgroundColor:"#fff",
    //   },
    // },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiPickersCalendarHeader:{
      dayLabel:{
        color: "#029CDC",
        
      }
    },

    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      inputAdornedEnd:{
        padding:'23px 15px'
      },
      inputAdornedStart:{
        paddingLeft:'15px'
      },
      input: {
        padding: "22.5px 14px",
       
        borderRadius: "5px",
      },
      notchedOutline: {
        borderColor: "#029CDC",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#fff",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiMenuItem:{
      root:{
       paddingLeft:'20px'
      }
    },
    MuiPaper: {
      
      rounded: {
        borderRadius: "10px",
      },
      rounded:{
        borderRadius:'0px',
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiStepper:{
      alternativeLabel:{
        background:'transparent',
      }
    },
   
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: '550 !important',
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm:{
        // width:'500px !important',
        maxWidth:'500px !important',
      },
     
      paperWidthXs:{
        maxWidth:'550px '
      }
    },
    MuiInputBase: {
     
      input: {
        fontSize: 16,
        color: "#000",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiSelect:{
      icon:{
        color:'#000',
        fontSize:'20px',
      }
    },
    MuiTableHead:{
      root:{
        background:"linear-gradient(90deg, rgb(57, 169, 244) 0%, rgb(98, 211, 240) 100%) rgb(2, 156, 220)",
        color:'#000',
        padding:'15px 0px !important',
      }
    },
    MuiTableCell:{
      root:{
        padding:"9px"
      },
      head:{
        fontSize:'16px',
      }
    },
    MuiDialogActions:{
      root:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
      }
    },
    MuiButton: {
      containedSecondary: {
        // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        color: "#fff",
        fontSize: "15px  !important",
        background: "linear-gradient(90deg, #39A9F4 0%, #62D3F0 100%)",
        width: "150px",
        height: "50px",
        // padding: "5px 19px",
      },

      containedPrimary: {
        backgroundColor: "#ffffff",
        // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        color: "#000",
        fontSize: "15px !important",
        height: "40px",
        lineHeight: " 21px",
        width: "150px",
        height: "50px",
        // padding: "10px 39px",
        marginRight: "10px",
        "&:hover": {
          backgroundColor: "#b2b2b2",
          color: "#fff",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#f30065",
        fontWeight: 600,
        padding: "5px 19px",

        "&:hover": {
          backgroundColor: "#f30065",

          color: "#fff",
        },
      },
      outlinedPrimary: {
        background:" #FFFFFF",
        border:" 1px solid #029CDC",
        borderRadius: "11px",
        color: "#000",
        fontWeight: 600,
        padding: "5px 19px",
        width: "150px",
        height: "50px",
        "&:hover": {
         
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "rgba(0, 0, 0, 0.54)",
        padding: "9px",
        overflow: "visible",
        fontSize: "14px",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000 ",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        
      },
    },
  },
};

const themesOptions = {
  
  palette: {
    
   
    primary: {
      main: "#47bcf2",
      color:'#fff'
      
    },
   
   
   
   
    
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
