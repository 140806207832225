export const baseurl = "https://node-pawsome.mobiloitte.org/"; // staging
// export const baseurl = "https://node.pawsome.host/"; // staging client

let base = `${baseurl}api/v1`;
let user = `${base}/user`;
let nft = `${base}/nft`;
let admin = `${base}/admin`;
let ststic = `${base}/static`;
let event = `${base}/event`;
let circuit = `${base}/circuit`;
let practice = `${base}/practice`;

const Apiconfigs = {
  // admin

  login: `${admin}/login`,
  forgotPassword: `${admin}/forgotPassword`,
  resetPassword: `${admin}/resetPassword/`,
  userList: `${admin}/userList`,
  socialLinkList: `${admin}/socialLinkList`,
  socialLink: `${admin}/socialLink`,
  blockUnblockUser: `${admin}/blockUnblockUser `,
  dashboard: `${admin}/dashboard`,
  user: `${admin}/user`,

  //StaticMangement
  staticContentList: `${ststic}/staticContentList`,
  staticContent: `${ststic}/staticContent`,

  //Event Management
  group: `${event}/group/`,
  listEvent: `${event}/listEvent`,
  joinEvent: `${event}/joinEvent`,
  enterEvent: `${event}/enterEvent`,
  eventRacingDetails: `${event}/eventRacingDetails`,
  eventRaceCompleteResult: `${event}/eventRaceCompleteResult`,
  getDogHistory: `${event}/getDogHistory`,
  refundFundForEvents: `${event}/refundFundForEvents`,

  //Practice Management
  listPractice: `${practice}/listPractice`,
  practice: `${practice}/practice`,
  allPracticeModeDetails: `${practice}/allPracticeModeDetails`,

  //userCalls
  connectWallet: `${user}/connectWallet`,
  getProfile: `${user}/getProfile`,
  editProfile: `${user}/editProfile`,
  uploadFile: `${user}/uploadFile`,
  subscribeNewsletter: `${user}/subscribeNewsletter`,
  socialLinks: `${user}/socialLinks`,
  getEventDetails: `${user}/getEventDetails`,
  petstores: `${user}/petstores`,
  likeDislikePetStore: `${user}/likeDislikePetStore`,
  buyPetstore: `${user}/buyPetstore`,
  likeDislikeEvent: `${user}/likeDislikeEvent`,

  //nftCalls
  createNFT: `${nft}/createNFT`,
  ipfsUpload: `${nft}/ipfsUpload`,
  uploadNFT: `${nft}/uploadNFT`,
  getMetadata: `${nft}/getMetadata`,
  setPetName: `${nft}/setPetName`,

  // event
  eventSchedule: `${event}/eventSchedule`,
  listEvent: `${event}/listEvent`,
  event: `${event}/event`,

  // circuit
  listCircuit: `${circuit}/listCircuit`,

  // user event live or upcoming

  upcomingEvents: `${user}/upcomingEvents`,
  pastEvents: `${user}/pastEvents`,
};

export default Apiconfigs;
