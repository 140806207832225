import React, { useContext } from "react";
import {
  Container,
  Grid,
  Box,
  IconButton,
  makeStyles,
  Typography,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { UserContext } from "src/context/User";

import { FiFacebook } from "react-icons/fi";
import { FaDiscord, FaTelegramPlane } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { FaMediumM } from "react-icons/fa";
import Logo from "src/component/Logo";

const useStyles = makeStyles((theme) => ({
  divider: {
    borderTop: "1px solid #C5C5C5",
    paddingBottom: "20px",
  },
  socialicons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& svg": {
      color: "#7D7E7E",
      cursor: "pointer",
      fontSize: "19px",
      paddingLeft: "11px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
      },
      "&:hover": {
        color: "#39A9F4",
      },
    },
  },
  linkbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& a": {
      color: "#959595",
      fontFamily: "Anybody",
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "30px",
      paddingRight: "20px",
      textDecoration: "none",
      "&:hover": {
        color: "#39A9F4",
      },
    },
  },
  logobox: {
    order: "4",
    "& p": {
      color: "#959595",
      fontSize: "12px",
      paddingTop: "15px",
      lineHeight: "30px",
      paddingLeft: "88px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
      },
    },
  },
  linkgrid: {
    order: "0",
    [theme.breakpoints.down("sm")]: {
      order: "1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  logogrid: {
    order: "1",
    [theme.breakpoints.down("sm")]: {
      order: "0",
    },
  },
  falsegrid: {
    order: "2",
  },
  socialgrid: {
    order: "3",

    "& .nonHover": {
      "& .img1": {
        display: "block",
        marginBottom: "5px",
        marginLeft: "10px",
        width: "21px",
      },
      "& .img2": {
        display: "none",
        marginBottom: "5px",
        marginLeft: "10px",
        width: "21px",
      },
      "&:hover": {
        "& .img1": {
          display: "none",
        },
        "& .img2": {
          display: "block",
        },
      },
    },
  },
}));
const Footersection = () => {
  const classes = useStyles();
  const user = useContext(UserContext);
  return (
    <Box pb={3}>
      <Container>
        <Box className={classes.divider}></Box>
        <Grid container spacing={2}>
          <Grid item lg={5} md={5} sm={12} xs={12} className={classes.linkgrid}>
            <Box className={classes.linkbox}>
              <RouterLink to="/about-us">About Us</RouterLink>
              {/* <RouterLink to="/dashboard">Dashboard</RouterLink> */}
              <RouterLink to="/privacy">Privacy Policy</RouterLink>
              <RouterLink to="/terms">Terms And Conditions</RouterLink>
            </Box>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            align="center"
            className={classes.logogrid}
          >
            {/* <img src="images/hovrlogo.png" alt="logo" width="100px" /> */}
            <Logo />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={4}
            xs={3}
            className={classes.falsegrid}
          ></Grid>
          <Grid item lg={2} md={2} sm={4} xs={6} className={classes.socialgrid}>
            <Box className={classes.socialicons}>
              <Link href={user?.socialData[0]?.link} target="_blank">
                <FiFacebook />
              </Link>

              <Link href={user?.socialData[3]?.link} target="_blank">
                <FaTelegramPlane />
              </Link>
              <Link href={user?.socialData[2]?.link} target="_blank">
                <FaInstagram />
              </Link>

              <Link href={user?.socialData[1]?.link} target="_blank">
                <FiTwitter />
              </Link>
              <Link
                href="https://discord.com/invite/9HCNTyqkwa"
                target="_blank"
              >
                <FaDiscord />
              </Link>
              <Link href="https://weibo.com/pawsome" target="_blank">
                <Box className="nonHover">
                  <img src="images/aa.png" alt="" className="img1" />{" "}
                  <img src="images/aa-1.png" alt="" className="img2" />
                </Box>
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.logobox} align="center">
          <Typography variant="body1">
            Copyright © 2022 PAWSOME, ALL RIGHTS RESERVED
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footersection;
